/*eslint-disable*/
import React from "react";
// used for making the prop types of this component
// reactstrap components
import {Container} from "reactstrap";

export default class Footer extends React.Component {
    render() {
        return (
            <footer className="footer">
                <Container fluid>
                    <div className="copyright">
                        © {new Date().getFullYear()} made by
                        IT HIT
                        for Express Instal.
                    </div>
                </Container>
            </footer>
        );
    }
}

