
import React from "react";
// react plugin used to create google maps
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker
} from "react-google-maps";

// reactstrap components
import {Card, CardHeader, CardBody, Row, Col} from "reactstrap";
import SendOrder from "../components/Tabs/SendOrder";
import OrderDetails from "../components/Tabs/OrderDetails";
import ClientDetails from "../components/Tabs/ClientDetails";

class EditClient extends React.Component {
    constructor(props) {
        super(props);
        this.urlParams = new URLSearchParams(this.props.location.search);

        this.state = {
            clientId: this.urlParams.get('id'),
            cod: this.urlParams.get('cod'),
            nume: this.urlParams.get('nume'),
            tel1: this.urlParams.get('tel1'),
            adresa: this.urlParams.get('adresa'),
            cod_postal: this.urlParams.get('cod_postal'),
            oras: this.urlParams.get('oras'),
            judet: this.urlParams.get('judet'),
            zona: this.urlParams.get('zona'),
            discount: this.urlParams.get('discount'),
            max_discount: this.urlParams.get('max_discount'),
            tip_plata: this.urlParams.get('tip_plata'),
            comentarii: this.urlParams.get('comentarii'),
        }
    }

    render() {
        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card className="card-plain">
                                <CardHeader>Editeaza comanda</CardHeader>
                                <CardBody>
                                    <ClientDetails colSize="12"
                                                   clientId={this.state.clientId}
                                                   cod={this.state.cod}
                                                   nume={this.state.nume}
                                                   tel1={this.state.tel1}
                                                   adresa={this.state.adresa}
                                                   cod_postal={this.state.cod_postal}
                                                   oras={this.state.oras}
                                                   judet={this.state.judet}
                                                   zona={this.state.zona}
                                                   discount={this.state.discount}
                                                   max_discount={this.state.max_discount}
                                                   tip_plata={this.state.tip_plata}
                                                   comentarii={this.state.comentarii}
                                    ></ClientDetails>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default EditClient;
