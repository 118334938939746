
import React from "react";
// react plugin used to create google maps
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";

// reactstrap components
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import LoginForm from "../components/Parts/LoginForm";

class Login extends React.Component {
  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <LoginForm />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Login;
