
import React from "react";
// react plugin for creating notifications over the dashboard
// reactstrap components
import {Button, Card, CardBody, CardTitle, Col, Row} from "reactstrap";
import SendMail from "../components/Tabs/SendMail";
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import CameraInputs from "../components/Tabs/CameraInputs";
import GoogleDriveUpload from "../components/Parts/GoogleDriveUpload";
import StartDrive from "../components/Tabs/StartDrive";
import axios from "axios";
import apiConfig from "../apiConfig";
import FinishDrive from "../components/Tabs/FinishDrive";
import PrepareOrders from "../components/Tabs/PrepareOrders";


class GestionarControl extends React.Component {
    constructor(props) {
        super(props);
        this.urlParams = new URLSearchParams(this.props.location.search);

        this.state = {
            clientId: this.urlParams.get('clientId'),
            phone: this.urlParams.get('phone'),
            email: this.urlParams.get('email'),
            clientName: this.urlParams.get('clientName'),
            content: <PrepareOrders colSize="12"/>,
            displayCamera: ''
        };

    }

    componentDidMount() {

    }

    handleTakePhoto(dataUri) {
        // Do stuff with the photo...
        console.log('takePhoto');
    }

    render() {
        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardBody>
                                    <div className="places-buttons">
                                        <Row>
                                            <Col className="ml-auto mr-auto text-center" md="6">
                                                <CardTitle tag="h4">
                                                    Control gestiune
                                                </CardTitle>
                                            </Col>
                                        </Row>
                                        <Row>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        {this.state.content}
                    </Row>
                </div>
            </>
        );
    }
}

export default GestionarControl;
