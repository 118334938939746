
import React from "react";
// reactstrap components
import {Button, Card, CardBody, CardHeader, CardTitle, Col, Form, FormGroup, Input, Row, Table} from "reactstrap";

class Reports extends React.Component {
    render() {
        return (
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">Reports</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Table className="tablesorter" responsive>
                                    <thead className="text-primary">
                                    <tr>
                                        <th>Id</th>
                                        <th>Timestamp</th>
                                        <th>Cod</th>
                                        <th className="text-center">Pret</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>123</td>
                                        <td>01-01-2020 10:00:00</td>
                                        <td>16x10</td>
                                        <td className="text-center">10 RON</td>
                                    </tr>
                                    <tr>
                                        <td>123</td>
                                        <td>01-01-2020 10:00:00</td>
                                        <td>16x10</td>
                                        <td className="text-center">10 RON</td>
                                    </tr>
                                    <tr>
                                        <td>123</td>
                                        <td>01-01-2020 10:00:00</td>
                                        <td>16x10</td>
                                        <td className="text-center">10 RON</td>
                                    </tr>
                                    <tr>
                                        <td>123</td>
                                        <td>01-01-2020 10:00:00</td>
                                        <td>16x10</td>
                                        <td className="text-center">10 RON</td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Reports;
