
import React from "react";
import axios from "axios";
// reactstrap components
import {Card, CardBody, CardHeader, CardTitle, Col, Row, Table} from "reactstrap";
import ArticlesFilter from "components/Filters/ArticlesFilter";
import apiConfig from "apiConfig.js";
import OrdersFilter from "../components/Filters/OrdersFilter";
import Moment from 'moment';
import PaginationComponent from "react-reactstrap-pagination";
import Cookies from 'js-cookie';

class Orders extends React.Component {
    PAGESIZE = 10;

    constructor(props) {
        super(props);
        let today = new Date();

        this.state = {
            dateStart: today.toISOString(),
            dateEnd: today.toISOString(),
            orders: [],
            totalItems: 0,
            isLoading: true,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSelected = this.handleSelected.bind(this);
    }

    componentDidMount() {
        this.setState({
            isLoading: false
        });
    }

    handleSelected(selectedPage) {
        console.log("selected", selectedPage);

        this.fetchOrdersData(selectedPage)
    }

    handleSubmit(event) {
        event.preventDefault();

        this.fetchOrdersData(1)
    }

    buildFiltersForRequest()    {
        let filters = '';

        let dateStart, dateEnd, code, agent, active;

        [dateStart, dateEnd, code, active] = this.getFiltersValue();

        let dateStartObject = new Date(dateStart);
        dateStartObject.setDate(dateStartObject.getDate() - 1);

        dateStart = dateStartObject.toISOString();

        filters = `SALDOC.SALESMAN=${Cookies.get('softone_id')}&SALDOC.TRNDATE=${dateStart}&SALDOC.TRNDATE_TO=${dateEnd}`

        if (code)   {
            filters += `&FINCODE=${code}=`;
        }

        if (active)   {
            if (filters === '')  {
                filters += `ISACTIVE=${active}`;
            }
            else {
                filters += `&ISACTIVE=${active}`;
            }
        }

        console.log(filters);


        return filters;
    }

    fetchOrdersData(page)   {
        this.setState({
            isLoading: true
        });

        let url = `${apiConfig.baseUrl}/orders`;
        let body = {
            'filters': this.buildFiltersForRequest(),
            'start': (page - 1) * this.PAGESIZE,
            'limit': this.PAGESIZE
        };

        axios.post(`${url}`, body).then((response) => {
            let responseData = response.data;
            this.setState({orders: []});

            if (responseData.success) {
                this.setState({
                    totalItems: responseData.totalCount,
                    orders: responseData.orders,
                    isLoading: false
                });
            } else {
                alert(responseData.error);
                console.warn(responseData.error);
            }
        });
    }

    getFiltersValue() {
        let dateStart = document.getElementById('filterDateStart').value;
        let dateEnd = document.getElementById('filterDateEnd').value;
        let code = document.getElementById('filterCode').value;
        let active = document.getElementById('filterActive').value;

        this.setState({
            dateStart: dateStart,
            dateEnd: dateEnd,
        });

        return [dateStart, dateEnd, code, active];
    }

    render() {
        Moment.locale('en');
        let tableContent;

        if (this.state.isLoading) {
            tableContent = apiConfig.loader
        }
        else {
            tableContent = <Table className="tablesorter" responsive>
                <thead className="text-primary">
                <tr>
                    <th>Data</th>
                    <th>Document</th>
                    <th>Filiala</th>
                    <th>Cod</th>
                    <th>Nume</th>
                    <th className="text-center">Valoare</th>
                    <th>Moneda</th>
                    <th>Anulat</th>
                    <th>Observatii</th>
                    <th>Agent</th>
                    <th>Prenume agent</th>
                    <th>Tranzactie</th>
                    <th>Client</th>
                </tr>
                </thead>
                <tbody>
                {
                    this.state.orders.map(order => (
                        <tr key = {order.document}>

                            <td>{Moment(order.data).format('D.MM.YYYY, h:mm')}</td>
                            <td>
                                <a href={`order/edit?orderId=${order.tranzactie}`}>
                                    {order.document}
                                </a>

                            </td>
                            <td>{order.filiala}</td>
                            <td>{order.cod}</td>

                            <td>{order.nume}</td>
                            <td>{order.valoare}</td>
                            <td>{order.moneda}</td>
                            <td>{order.anulat}</td>

                            <td>{order.observatii}</td>
                            <td>{order.agent}</td>
                            <td>{order.prenume}</td>
                            <td>{order.tranzactie}</td>
                            <td>{order.client}</td>
                        </tr>
                    ))
                }
                </tbody>
            </Table>
        }

        return (
            <div className="content">
                <Row>
                    <OrdersFilter colSize="12" defaultStartDate={this.state.dateStart} defaultEndDate={this.state.dateEnd}
                                  handleSubmit={this.handleSubmit}/>
                </Row>
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">Comenzi</CardTitle>
                            </CardHeader>
                            <CardBody>
                                {tableContent}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="pr-md-1" md={{size: 2, offset: 8}}>
                        <PaginationComponent totalItems={this.state.totalItems}
                                             pageSize={this.PAGESIZE}
                                             onSelect={this.handleSelected}/>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Orders;
