/*eslint-disable*/
import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// reactstrap components
import {
    Row,
    Card,
    CardHeader,
    CardBody,
    Form,
    Col,
    FormGroup,
    Input, CardFooter, Button
} from "reactstrap";

class SendOrder extends React.Component {
    render() {
        return (
            <Col md={this.props.colSize}>
                <Card>
                    <CardHeader>
                        <h5 className="title">Send order</h5>
                    </CardHeader>
                    <CardBody>
                        <Form>
                            <Row>
                                <Col className="pr-md-1" md="6">
                                    <FormGroup>
                                        <label>First Name</label>
                                        <Input
                                            defaultValue="Mike"
                                            placeholder="Company"
                                            type="text"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col className="pl-md-1" md="6">
                                    <FormGroup>
                                        <label>Last Name</label>
                                        <Input
                                            defaultValue="Andrew"
                                            placeholder="Last Name"
                                            type="text"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <FormGroup>
                                        <label>Product code</label>
                                        <Input
                                            defaultValue="[Express Install] Mail subject"
                                            placeholder="Mail subject"
                                            type="text"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </CardBody>
                    <CardFooter>
                        <Button className="btn-fill" color="primary" type="submit">
                            Send
                        </Button>
                    </CardFooter>
                </Card>
            </Col>
        );
    }
}

export default SendOrder;
