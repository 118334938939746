import React from "react";
// react plugin used to create google maps
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker
} from "react-google-maps";

// reactstrap components
import {Card, CardHeader, CardBody, Row, Col, CardFooter, Button} from "reactstrap";
import SendOrder from "../components/Tabs/SendOrder";
import OrderDetails from "../components/Tabs/OrderDetails";
import OrderDetailsGestionar from "../components/Tabs/OrderDetailsGestionar";
import axios from "axios";
import apiConfig from "../apiConfig";

class LoadOrderView extends React.Component {
    constructor(props) {
        super(props);
        this.urlParams = new URLSearchParams(this.props.location.search);

        this.state = {
            orderId: this.urlParams.get('orderId')
        }


    }

    render() {
        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card className="card-plain">
                                <CardHeader>Incarca comanda</CardHeader>
                                <CardBody>
                                    <OrderDetailsGestionar colSize="12" orderId={this.state.orderId}
                                                           scanType={"incarcata"}></OrderDetailsGestionar>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default LoadOrderView;
