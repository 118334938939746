/*eslint-disable*/
import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// reactstrap components
import {
    Row,
    Card,
    CardHeader,
    CardBody,
    Form,
    Col,
    FormGroup,
    Input, CardFooter, Button, Table, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import axios from "axios";
import apiConfig from "../../apiConfig";
import Loader from "react-loader-spinner";
import Cookies from 'js-cookie';
import Articles from "../../views/Articles";
import Clients from "../../views/Clients";
import DatePicker from "reactstrap-date-picker";

class OrderDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: '',
            document: '',
            filiala: '',
            clientId: '',
            cod: '',
            companie: '',
            valoare: '',
            moneda: 'RON',
            anulat: '',
            observatii: '',
            orderArticles: [],
            editedArticleId: -1,
            showModal: false,
            showModalClient: false,
            currentDate: new Date().toISOString(),
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.addArticle = this.addArticle.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.toggleModalClient = this.toggleModalClient.bind(this);
        this.handleArticleSelect = this.handleArticleSelect.bind(this);
        this.handleClientSelect = this.handleClientSelect.bind(this);
    }

    componentDidMount() {
        let requestBody = {
            filters: ""
        }

        if (this.props.orderId) {
            this.setState({
                isLoading: true
            })
            axios.post(`${apiConfig.baseUrl}/orders/details/${this.props.orderId}`, requestBody).then((response) => {
                let responseData = response.data;

                if (responseData.success) {
                    this.setOrderFields(responseData.orderDetails, responseData.orderItems)
                } else {
                    alert(responseData.error);
                }
            });
        } else {
            this.setState({data: this.state.currentDate});
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setOrderFields(orderDetails, orderItems) {
        let formattedOrderArticles = [];

        for (let index in orderItems) {
            let article = orderItems[index];
            article.linenum = parseInt(index) + 1;

            formattedOrderArticles.push(article);
        }

        this.setState({
            data: orderDetails.data,
            document: orderDetails.document,
            filiala: orderDetails.filiala,
            cod: orderDetails.cod,
            companie: orderDetails.nume,
            valoare: orderDetails.valoare_totala,
            moneda: orderDetails.moneda,
            anulat: orderDetails.anulat,
            observatii: orderDetails.observatii,
            orderArticles: formattedOrderArticles,
            isLoading: false,
            clientId: orderDetails.client,
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        let updatedFields = {
            "SERIES": document.getElementById('tip_comanda').value,
            "TRNDATE": this.state.currentDate,
            "TRDR": this.state.clientId,
            "SUMAMNT": this.state.valoare,
            "SOCURRENCY": this.state.moneda,
            "ISCANCEL": this.state.anulat,
            "COMMENTS": this.state.observatii,
            "SALESMAN": Cookies.get('softone_id'),
        };

        let extralines = [];

        this.state.orderArticles.forEach(article => {
            let articleLine = {
                "LINENUM": article.linenum,
                "MTRL": article.articol,
                "PRICE": article.pret_ridicata,
                "QTY1": article.cantitate
            };
            if (article.cantitate > 0) {
                extralines.push(articleLine);
            }
        });

        let url;

        if (this.props.orderId) {
            url = `${apiConfig.baseUrl}/orders/update?orderId=${this.props.orderId}`;
        } else {
            url = `${apiConfig.baseUrl}/orders/insert`;
        }

        let requestBody = {
            fields: updatedFields,
            extraLines: extralines,
        }

        axios.post(url, requestBody).then((response) => {
            let responseData = response.data;

            if (responseData.success) {
                console.log(responseData);
                alert('Comanda a fost salvata cu succes');
                window.location.href = '/admin/orders';
            } else {
                alert(responseData.error);
            }
        });
    }

    addArticle(id, cod, nume, cantitate, pret, valoare, valoare_tva) {

        let articleObject = {
            linenum: this.calculateLastLineNumForArticles() + 1,
            articol: id,
            cod: cod,
            nume: nume,
            cantitate: cantitate,
            pret: pret,
            valoare: valoare,
            valoare_tva: valoare_tva
        }
        let articlesList = this.state.orderArticles;

        articlesList.push(articleObject)

        this.setState({orderArticles: articlesList});
    }

    calculateLastLineNumForArticles() {
        let lastLineNum = 9000000;

        this.state.orderArticles.forEach(article => {
            if (article.linenum > lastLineNum) {
                lastLineNum = article.linenum;
            }
        });

        return lastLineNum;
    }

    addClient(id, cod, nume) {

        this.setState({
            clientId: id,
            cod: cod,
            companie: nume,
        });
    }

    toggleModal() {
        this.setState({
            showModal: !this.state.showModal,
        })
    }

    toggleModalClient() {
        this.setState({
            showModalClient: !this.state.showModalClient,
        })
    }

    handleClientSelect(event) {
        event.preventDefault();
        let clientId = event.target.id;
        let clientCode = event.target.getAttribute('data-code');
        let clientName = event.target.getAttribute('data-name');

        this.addClient(clientId, clientCode, clientName);

        this.toggleModalClient()
    }

    handleArticleSelect(event) {
        event.preventDefault();
        let articleId = event.target.id;
        let articleCode = event.target.getAttribute('data-code');
        let articleName = event.target.getAttribute('data-name');
        let articlePrice = event.target.getAttribute('data-price');

        this.addArticle(articleId, articleCode, articleName, 0, articlePrice, articlePrice, 0);

        this.toggleModal()
    }

    updateOrderArticleQuantities(articleId, initialQuantity) {
        let updatedArticles = [];

        this.state.orderArticles.forEach((article) => {
            if (article.articol == articleId) {
                article.cantitate = initialQuantity;
            }
            updatedArticles.push(article)
        });

        this.setState({orderArticles: updatedArticles})
    }

    render() {
        let formContent;
        if (this.state.isLoading) {
            formContent = apiConfig.loader
        } else {
            formContent = <Form onSubmit={this.handleSubmit}>
                <Row>
                    <Col className="pr-md-1" md="6">
                        <FormGroup>
                            <label htmlFor="tip_comanda">Tip comanda</label>
                            <Input type="select" name="tip_comanda" id="tip_comanda">
                                <option value="7021">Comanda</option>
                                <option value="7042">Dispozitie de livrare</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6">
                        <FormGroup>
                            <label htmlFor="data">Data</label>

                            <DatePicker id="data"
                                        value={this.state.data}
                            />
                        </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">

                        <FormGroup>
                            <label htmlFor="document">Document</label>
                            <Input type="text" id="document" value={this.state.document}/>
                        </FormGroup>
                    </Col>
                </Row>


                <Row>
                    <Col className="pl-md-1" md="6">
                        <Button outline className="btn-fill" color="primary" onClick={this.toggleModalClient}
                                style={{display: Cookies.get('role') == 'agent' ? 'block' : 'none'}}>
                            Selecteaza client
                        </Button>
                    </Col>
                </Row>

                <Row>
                    <Col className="pl-md-1" md="6">
                        <FormGroup>
                            <label htmlFor="document">Cod</label>
                            <Input type="text" id="cod" defaultValue={this.state.cod} onChange={(e) => {
                                this.setState({cod: e.target.value});
                            }}/>
                        </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6">
                        <FormGroup>
                            <label htmlFor="data">Nume companie</label>
                            <Input type="text" id="companie" defaultValue={this.state.companie} onChange={(e) => {
                                this.setState({companie: e.target.value});
                            }}/>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="pr-md-1" md="6">
                        <FormGroup>
                            <label htmlFor="data">Valoare</label>
                            <Input type="text" id="valoare" defaultValue={this.state.valoare} onChange={(e) => {
                                this.setState({valoare: e.target.value});
                            }}/>
                        </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">

                        <FormGroup>
                            <label htmlFor="document">Moneda</label>
                            <Input type="text" id="moneda" defaultValue={this.state.moneda} onChange={(e) => {
                                this.setState({moneda: e.target.value});
                            }}/>
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col className="pr-md-1" md="6">
                        <FormGroup>
                            <label htmlFor="data">Anulat</label>
                            <Input type="text" id="anulat" defaultValue={this.state.anulat} onChange={(e) => {
                                this.setState({anulat: e.target.value});
                            }}/>
                        </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                        <FormGroup>
                            <label htmlFor="document">Observatii</label>
                            <Input type="text" id="observatii" defaultValue={this.state.observatii} onChange={(e) => {
                                this.setState({observatii: e.target.value});
                            }}/>
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col className="pr-md-1" md="12">
                        <Table className="tablesorter" responsive>
                            <thead className="text-primary">
                            <tr>
                                <th>Id</th>
                                <th>Nume</th>
                                <th>Cod</th>
                                <th>Cantitate</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.orderArticles.map(article => (
                                    <tr key={article.articol}>
                                        <td>{article.articol}</td>
                                        <td>{article.nume}</td>
                                        <td>{article.cod}</td>
                                        <td>
                                            <Input type="text" id={`${article.articol}_initial_quantity`}
                                                   defaultValue={article.cantitate} onChange={(e) => {
                                                this.updateOrderArticleQuantities(article.articol, e.target.value)
                                            }}/>
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>

                        </Table>
                        <Button outline className="btn-fill" color="primary" onClick={this.toggleModal}
                                style={{display: Cookies.get('role') == 'agent' ? 'block' : 'none'}}>
                            (+) Adauga articol nou
                        </Button>

                        <Button className="btn-fill" color="primary" type="submit"
                                style={{display: Cookies.get('role') == 'agent' ? 'block' : 'none'}}>
                            Salveaza
                        </Button>
                    </Col>
                </Row>
            </Form>;
        }

        return (

            <Col md={this.props.colSize}>
                <Modal size={"lg"} isOpen={this.state.showModal} toggle={this.toggleModal} style={{opacity: 1,  height: '100%', width: '100%'}}>
                    <ModalHeader>
                        Selectie articol
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={12}>
                                <Articles loadedFrom={"OrderDetails"} handleArticleSelect={this.handleArticleSelect}/>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn-fill" color="primary" onClick={this.addArticle}>
                            Adauga
                        </Button>
                    </ModalFooter>
                </Modal>

                <Modal size={"lg"} isOpen={this.state.showModalClient} toggle={this.toggleModalClient}
                       style={{opacity: 1, height: '100%', width: '100%'}}>
                    <ModalHeader>
                        Selectie client
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={12}>
                                <Clients loadedFrom={"OrderDetails"} handleClientSelect={this.handleClientSelect}/>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
                <Card>
                    <CardHeader>
                        <h5 className="title">Detalii comanda</h5>
                    </CardHeader>
                    <CardBody>
                        {formContent}
                    </CardBody>
                    <CardFooter>

                    </CardFooter>
                </Card>
            </Col>
        );
    }
}

export default OrderDetails;
