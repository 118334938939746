import React from "react";
// react plugin for creating notifications over the dashboard
// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardTitle,
    Col,
    Form,
    FormGroup,
    Input,
    Row,
    Table
} from "reactstrap";
import SendMail from "../components/Tabs/SendMail";
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import CameraInputs from "../components/Tabs/CameraInputs";
import GoogleDriveUpload from "../components/Parts/GoogleDriveUpload";
import StartDrive from "../components/Tabs/StartDrive";
import axios from "axios";
import apiConfig from "../apiConfig";
import FinishDrive from "../components/Tabs/FinishDrive";
import PrepareOrders from "../components/Tabs/PrepareOrders";
import LoadOrders from "../components/Tabs/LoadOrders";
import Cookies from "js-cookie";


class Inventory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            articles: [],
            showModal: false,
            scannedBarcodeDetails: {
                id: -1,
                nume_articol: "",
                cantitate: 0,
                unitate_masura1: "",
                unitate_masura2: "",
                barcode: "",
            },
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleBarCodeChange = this.handleBarCodeChange.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
        let requestBody = {
            filters: ""
        }
        this.setState({
            isLoading: true
        })
        this.fetchArticlesData();
    }

    fetchArticlesData() {
        console.log('se apeleaza')
        let url = `${apiConfig.baseUrl}/articles`;
        let body = {
            'filters': `ITEM.ISACTIVE=1`,
            'start': 0,
            'limit': 10000
        };

        axios.post(`${url}`, body).then((response) => {
            let responseData = response.data;

            if (responseData.success) {
                this.setState({
                    totalItems: responseData.totalCount,
                    articles: responseData.articles,
                    isLoading: false
                });
            } else {
                alert(responseData.error);
            }
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleSubmit(event) {
        event.preventDefault();
    }

    handleBarCodeChange(event) {
        event.preventDefault();

        let barcodeInput = event.target;
        let barcode = barcodeInput.value;

        axios.get(`${apiConfig.baseUrl}/articles/barcode/${barcode}`)
            .then((response) => {
                let responseData = response.data;

                if (responseData.success) {
                    let barcodeDetails = responseData.barcode_details
                    this.setState({scannedBarcodeDetails: barcodeDetails});

                    console.log(`${barcodeDetails.id}_inventory_scanned_quantity`);
                    let inventoryColumn = document.getElementById(`${barcodeDetails.id}_inventory_scanned_quantity`);
                    let scannedInventoryUntilNow = parseInt(inventoryColumn.innerText);

                    console.log(barcodeDetails);
                    inventoryColumn.innerText = scannedInventoryUntilNow + parseInt(barcodeDetails.cantitate)
                    barcodeInput.value = ''

                } else {
                    alert(responseData.error);
                }
            })
            .catch((error) => {
                console.log(error);
                alert('A intervenit o eroare. Va rugam verificati ca acest cod de bare este corect si se afla pe comanda!')
                barcodeInput.value = '';
                barcodeInput.focus();
            });
        ;
    }

    toggleModal() {
        this.setState({
            showModal: !this.state.showModal
        })
    }

    updateOrderArticleQuantities(articleId, initialQuantity) {
        let updatedArticles = [];

        this.state.articles.forEach((article) => {
            if (article.id == articleId) {
                article.cantitate = initialQuantity;
            }
            updatedArticles.push(article)
        });

        this.setState({articles: updatedArticles})
    }

    render() {
        let formContent;
        if (this.state.isLoading) {
            formContent = apiConfig.loader
        } else {
            formContent = <Form>
                <Row>
                    <Col className="pr-md-1" md="6">
                        <FormGroup>
                            <label htmlFor="barcode">Cod de bare</label>
                            <Input autoFocus type="text" id="barcode" onBlur={this.handleBarCodeChange}/>
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col className="pr-md-1" md="12">
                        <Table className="tablesorter" responsive>
                            <thead className="text-primary">
                            <tr>
                                <th>Id</th>
                                <th>Nume</th>
                                <th>Cod</th>
                                <th>Cantitate</th>
                                <th>Pret amanunt</th>
                                <th>Pret ridicata</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.articles.map(article => (
                                    <tr key={article.id}>
                                        <td>{article.id}</td>
                                        <td>{article.nume}</td>
                                        <td>{article.cod}</td>
                                        <td id={`${article.id}_inventory_scanned_quantity`}>
                                            {article.cantitate ?? 0}
                                        </td>
                                        <td>{article.pret_ridicata}</td>
                                        <td>{article.pret_amanunt}</td>
                                    </tr>
                                ))
                            }
                            </tbody>

                        </Table>
                    </Col>
                </Row>
            </Form>;
        }

        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardBody>
                                    <div className="places-buttons">
                                        <Row>
                                            <Col className="ml-auto mr-auto text-center" md="6">
                                                <CardTitle tag="h4">
                                                    Control gestiune
                                                </CardTitle>
                                            </Col>
                                        </Row>
                                        <Row>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>

                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <h5 className="title">Scanare inventar</h5>
                                </CardHeader>
                                <CardBody>
                                    {formContent}
                                </CardBody>
                                <CardFooter>
                                    <Button className="btn-fill" color="primary" type="submit"
                                            style={{display: Cookies.get('role') == 'gestionar' ? 'block' : 'none'}}>
                                        Salveaza
                                    </Button>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>


        );
    }
}

export default Inventory;
