import React from "react";
import axios from "axios";
// reactstrap components
import {Button, Card, CardBody, CardHeader, CardTitle, Col, Row, Table} from "reactstrap";
import ArticlesFilter from "components/Filters/ArticlesFilter";
import apiConfig from "apiConfig.js";
import Cookies from "js-cookie";
import PaginationComponent from "react-reactstrap-pagination";

class Articles extends React.Component {
    PAGESIZE = 10;

    constructor(props) {
        super(props);
        this.state = {
            articles: [],
            totalItems: 0,
            isLoading: true,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSelected = this.handleSelected.bind(this);

    }

    componentDidMount() {
        this.setState({
            isLoading: false
        });
    }

    handleSelected(selectedPage) {
        console.log("selected", selectedPage);

        this.fetchArticlesData(selectedPage)
    }

    handleSubmit(event) {
        event.preventDefault();

        this.fetchArticlesData(1);
    }

    buildFiltersForRequest() {
        let filters = '';
        let code, name, group, active;

        [code, name, group, active] = this.getFiltersValue();

        //ITEM.CODE=${code}&ITEM.NAME=${name}&ITEM.MTRGROUP=${group}&ITEM.ISACTIVE=${active}

        if (code) {
            filters += `CODE=${code}=`;
        }

        if (name) {
            if (filters === '') {
                filters += `NAME=${name}=`;
            } else {
                filters += `&NAME=${name}=`;
            }
        }

        if (group) {
            if (filters === '') {
                filters += `MTRGROUP=${group}=`;
            } else {
                filters += `&MTRGROUP=${group}=`;
            }
        }

        if (active) {
            if (filters === '') {
                filters += `ISACTIVE=${active}`;
            } else {
                filters += `&ISACTIVE=${active}`;
            }
        }

        return filters;
    }

    fetchArticlesData(page) {
        this.setState({
            isLoading: true
        });

        let url = `${apiConfig.baseUrl}/articles`;

        let body = {
            'filters': this.buildFiltersForRequest(),
            'start': (page - 1) * this.PAGESIZE,
            'limit': this.PAGESIZE
        };

        axios.post(`${url}`, body).then((response) => {
            let responseData = response.data;
            this.setState({articles: []});

            if (responseData.success) {
                // let filteredArticles = responseData.articles.filter(article => article.pret_ridicata > 0 || article.pret_amanunt > 0);
                this.setState({
                    totalItems: responseData.totalCount,
                    articles: responseData.articles,
                    isLoading: false
                });
            } else {
                alert(responseData.error);
            }
        });
    }

    filterArticlesWithPrice(articles)   {
        return articles.filter(article => article.pret_ridicata > 0);
    }

    getFiltersValue() {
        let code = document.getElementById('filterCode').value;
        let name = document.getElementById('filterName').value;
        let group = document.getElementById('filterGroup').value;
        let active = document.getElementById('filterActive').value;

        return [code, name, group, active];
    }

    render() {
        let tableContent;

        if (this.state.isLoading) {
            tableContent = apiConfig.loader
        } else {
            let extraColumnHeading = '';
            if (this.props.loadedFrom === 'OrderDetails') {
                extraColumnHeading = <th></th>
            }

            tableContent = <Table className="tablesorter" responsive>
                <thead className="text-primary">
                <tr>
                    {extraColumnHeading}
                    <th>Id</th>
                    <th>Nume</th>
                    <th>Cod</th>
                    <th>Grupa</th>
                    <th>Pret amanunt</th>
                    <th>Pret ridicata</th>
                    <th>Stoc</th>
                </tr>
                </thead>
                <tbody>
                {
                    this.state.articles.map(article => {
                        let optionalColumn = '';

                        if (this.props.loadedFrom === 'OrderDetails') {
                            optionalColumn =
                                <td>
                                    <Button className="btn-fill" color="primary"
                                            onClick={this.props.handleArticleSelect}
                                            id={article.id}
                                            data-code={article.cod}
                                            data-name={article.nume}
                                            data-price={article.pret_ridicata}
                                    >
                                        Selecteaza
                                    </Button>
                                </td>
                        }
                        return (
                            <tr key={article.id}>
                                {optionalColumn}
                                <td>{article.id}</td>
                                <td>{article.nume}</td>
                                <td>{article.cod}</td>
                                <td>{article.grupa}</td>
                                <td>{article.pret_amanunt}</td>
                                <td>{article.pret_ridicata}</td>
                                <td>{article.depozit}</td>
                            </tr>
                        );
                    })
                }
                </tbody>
            </Table>
        }

        return (
            <div className="content">
                <Row>

                </Row>
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <ArticlesFilter colSize="12" handleSubmit={this.handleSubmit}/>
                                <CardTitle tag="h4">Articole</CardTitle>
                            </CardHeader>
                            <CardBody>
                                {tableContent}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="pr-md-1" md={{size: 12}}>
                        <PaginationComponent totalItems={this.state.totalItems}
                                             pageSize={this.PAGESIZE}
                                             onSelect={this.handleSelected}/>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Articles;
