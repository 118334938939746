/*eslint-disable*/
import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// reactstrap components
import {
    Row,
    Card,
    CardHeader,
    CardBody,
    Form,
    Col,
    FormGroup,
    Input, CardFooter, Button, Table, ModalHeader, ModalBody, ModalFooter, Modal
} from "reactstrap";
import axios from "axios";
import apiConfig from "../../apiConfig";
import Moment from "moment";
import FinishDrive from "./FinishDrive";
import * as Scroll from 'react-scroll';
import {Link, Element, Events, animateScroll as scroll, scrollSpy, scroller} from 'react-scroll'
import Cookies from 'js-cookie'
import Articles from "../../views/Articles";

class StartDrive extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            cars: [],
            orders: [],
            isLoading: true,
            finishDriveContent: '',
            scrollPosition: 0,
            showModal: false,
        }
        this.toggleModal = this.toggleModal.bind(this);
        this.handleMailSubmit = this.handleMailSubmit.bind(this);
    }

    componentDidMount() {
        this.setState({
            isLoading: false
        });


        this.fetchOrdersData()
    }


    handleMailSubmit(event) {
        event.preventDefault();

        let requestBody = {
            "receiver_name": this.state.orders[0].nume,
            "receiver_email": this.state.orders[0].client_email,
            "subject": `[Express Instal] Comanda livrata`,
            "body": `Buna ziua, soferul nostru a livrat comanda XYZ. (va fi template HTML)`
        }

        console.log(requestBody);
        axios.post(`${apiConfig.baseUrl}/notification/mail/send`, requestBody).then((response) => {
            let responseData = response.data;

            if (responseData.success) {
                alert('Ati trimis cu succes mail-ul catre client!')
            } else {
                alert(responseData.error);
            }
        });
    }

    fetchOrdersData() {
        this.setState({
            isLoading: true
        });

        let url = `${apiConfig.baseUrl}/orders/getByDriver/${Cookies.get('softone_id')}`;

        axios.get(`${url}`).then((response) => {
            let responseData = response.data;
            this.setState({orders: []});

            if (responseData.success) {
                this.setState({
                    totalItems: responseData.totalCount,
                    orders: responseData.orders,
                    isLoading: false
                });
            } else {
                alert(responseData.error);
            }
        })
            .catch(err => {
                console.log(err);
            });
    }

    scrollDown(amountToScroll) {
        this.setState({
            scrollPosition: this.state.scrollPosition - amountToScroll
        })
        window.scrollTo(0, this.state.scrollPosition)
    }

    toggleModal() {
        this.setState({
            showModal: !this.state.showModal,
        })
    }


    render() {
        let tableContent;

        if (this.state.isLoading) {
            tableContent = apiConfig.loader
        } else {
            tableContent = <Table className="tablesorter" responsive>
                <thead className="text-primary">
                <tr>
                    <th>Nume client</th>
                    <th>Email client</th>
                    <th>Id comanda</th>
                    <th>Valoare</th>
                    <th>Actiuni</th>
                </tr>
                </thead>
                <tbody>
                {
                    this.state.orders.map(order => (
                            <tr key={order.document}>
                                <td>{order.nume}</td>
                                <td>{order.client_email}</td>
                                <td>
                                    <a href={`/admin/order/edit?orderId=${order.tranzactie}`}>
                                        {order.document}
                                    </a>

                                </td>
                                <td>{order.valoare}</td>
                                <td>

                                    <Button className='btn-fill' onClick={() => window.open(`/admin/sofer/order/unload?orderId=${order.tranzactie}`)}> Descarca comanda </Button>
                                    &nbsp;&nbsp;
                                    <Button className='btn-fill' onClick={() => {
                                        this.setState({
                                            finishDriveContent: <FinishDrive colSize="12" orderId={order.tranzactie}
                                                                             handleMailSubmit={this.handleMailSubmit}/>
                                        })

                                        this.toggleModal();
                                    }
                                    }> Finalizeaza livrare </Button>

                                </td>
                            </tr>
                        )
                    )
                }

                </tbody>
            </Table>
        }

        return (
            <Col md={this.props.colSize}>
                <Modal size={"lg"} isOpen={this.state.showModal} toggle={this.toggleModal} style={{opacity: 1, position: 'absolute', top: '-30vh'}}>
                    <ModalHeader>
                        Finalizeaza livrare
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            {this.state.finishDriveContent}
                        </Row>
                    </ModalBody>
                </Modal>
                <Card>
                    <CardHeader>
                        <h5 className="title">Lista comenzi</h5>
                    </CardHeader>
                    <CardBody>
                        <Form>
                            <Row>
                                <Col md="12">
                                    Lista comenzi de livrat:
                                    <Row>
                                        <Col md="12">
                                            {tableContent}
                                        </Col>
                                        <Col md="6">
                                            {this.state.finishDriveContent}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>


                    </CardBody>
                    <CardFooter>

                    </CardFooter>
                </Card>
            </Col>
        );
    }
}

export default StartDrive;
