/*eslint-disable*/
import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// reactstrap components
import {
    Row,
    Card,
    CardHeader,
    CardBody,
    Form,
    Col,
    FormGroup,
    Input, CardFooter, Button
} from "reactstrap";

import {BsCamera} from "react-icons/bs";
import FileInput from "../Parts/FileInput";
import axios from "axios";
import apiConfig from "../../apiConfig";
import FileInputComponent from "react-file-input-previews-base64";
import Cookies from 'js-cookie'

class CameraInputs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentFileInputsId: 2,
            optionalFileInputs: [],
            uploadedFiles: []
        }

        this.sendImagesToMail = this.sendImagesToMail.bind(this)
        this.appendBase64ToState = this.appendBase64ToState.bind(this)
    }

    appendBase64ToState(file) {
        let uploadedFilesUntilNow = this.state.uploadedFiles;
        console.log(this.state.uploadedFiles);
        uploadedFilesUntilNow.push(file.base64);

        this.setState({uploadedFiles: uploadedFilesUntilNow});
    }

    sendImagesToMail(event) {
        event.preventDefault();

        console.log('-------- INTRA AICI ---------')
        let requestBody;
        requestBody = {
            "receiver_name": "Erik Henning",
            "receiver_email": "erik.henning@ithit.ro",
            "subject": `[Express Instal] Bilete la ordin incarcate de agentul ${Cookies.get('name')}`,
            "body": `Mai jos se pot gasi biletele la ordin incarcate de agent pentru clientul ${this.props.clientEmail}`,
            "base64images": this.state.uploadedFiles
        }

        axios.post(`${apiConfig.baseUrl}/notification/mail/sendFile`, requestBody).then((response) => {
            let responseData = response.data;

            if (responseData.success) {
                alert('Biletele la ordin au fost incarcate cu succes')
            } else {
                alert(responseData.error);
            }
        });
    }

    render() {
        let fileInputs = this.state.optionalFileInputs;

        return (
            <Col md={this.props.colSize}>
                <Card>
                    <CardHeader>
                        <h5 className="title">Incarca imagine</h5>
                    </CardHeader>
                    <CardBody>
                        <Form>
                            <Row>
                                <Col className="pr-md-1" md="6">
                                    <FormGroup id="fileInputs">
                                        <FileInputComponent
                                            labelText={`Incarca poza 1`}
                                            labelStyle={{fontSize: 12}}
                                            multiple={false}
                                            callbackFunction={(file_arr) => {
                                                this.appendBase64ToState(file_arr)
                                            }}
                                            accept="image/*;capture=camera"
                                            id={`file1`}
                                        />
                                        <FileInputComponent
                                            labelText={`Incarca poza 2`}
                                            labelStyle={{fontSize: 12}}
                                            multiple={false}
                                            callbackFunction={(file_arr) => {
                                                this.appendBase64ToState(file_arr)
                                            }}
                                            accept="image/*;capture=camera"
                                            id={`file1`}
                                        />

                                        {
                                            this.state.optionalFileInputs.map(fileInput => fileInput)
                                        }
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="pr-md-1" md="6">
                                    <FormGroup>
                                        <Button className="btn-fill" color="primary"
                                                onClick={() => {
                                                    let fileInputId = this.state.currentFileInputsId + 1;
                                                    fileInputs.push(
                                                        <FileInputComponent
                                                            key={fileInputId}
                                                            labelText={`Incarca poza ${fileInputId}`}
                                                            labelStyle={{fontSize: 12}}
                                                            multiple={false}
                                                            callbackFunction={(file_arr) => {
                                                                this.appendBase64ToState(file_arr)
                                                            }}
                                                            accept="image/*;capture=camera"
                                                            id={`file${fileInputId}`}
                                                        />
                                                    );

                                                    console.log(fileInputs)

                                                    this.setState({
                                                        optionalFileInputs: fileInputs,
                                                        currentFileInputsId: fileInputId
                                                    })
                                                }
                                                }
                                        > Adauga mai multe imagini </Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </CardBody>
                    <CardFooter>
                        <Button className="btn-fill" color="primary" type="submit" onClick={this.sendImagesToMail}>
                            Trimite
                        </Button>
                    </CardFooter>
                </Card>
            </Col>
        );
    }
}

export default CameraInputs;
