import React from "react";
import axios from "axios";
// reactstrap components
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Row,
    Table,
    Pagination,
    PaginationItem,
    PaginationLink, Button, ModalHeader, ModalBody, Form, FormGroup, Input, ModalFooter, Modal
} from "reactstrap";
import ClientsFilter from "components/Filters/ClientsFilter";
import apiConfig from "apiConfig.js";
import PaginationComponent from "react-reactstrap-pagination";
import logo from "../assets/img/express-logo.png";
import Loader from 'react-loader-spinner'
import Cookies from 'js-cookie';

class Clients extends React.Component {
    PAGESIZE = 10;

    constructor(props) {
        super(props);
        this.state = {
            clients: [],
            totalItems: 0,
            isLoading: true,
            soldComposition: [],
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSelected = this.handleSelected.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
        this.setState({
            isLoading: false
        });
    }

    handleSelected(selectedPage) {
        // console.log("selected", selectedPage);

        this.fetchClientsData(selectedPage)
    }

    handleSubmit(event) {
        event.preventDefault();

        this.fetchClientsData(1);
    }

    buildFiltersForRequest() {
        let code, name, zone, active;
        let filters = '';

        [code, name, zone, active] = this.getFiltersValue();

        // console.log([code, name, zone, active])
        if (code) {
            filters += `CODE=${code}=`;
        }

        if (name) {
            if (filters === '') {
                filters += `NAME=${name}=`;
            } else {
                filters += `&NAME=${name}=`;
            }
        }

        if (active) {
            if (filters === '') {
                filters += `ISACTIVE=${active}`;
            } else {
                filters += `&ISACTIVE=${active}`;
            }
        }


        return filters;
    }

    fetchClientsData(page) {
        this.setState({
            isLoading: true
        });

        let url = `${apiConfig.baseUrl}/clients?agentId=${Cookies.get('softone_id')}`;
        let body = {
            'filters': this.buildFiltersForRequest(),
            'start': (page - 1) * this.PAGESIZE,
            'limit': this.PAGESIZE
        };

        // console.log(body);

        axios.post(`${url}`, body).then((response) => {
            let responseData = response.data;
            this.setState({clients: []});

            if (responseData.success) {
                this.setState({
                    totalItems: responseData.totalCount,
                    clients: responseData.clients,
                    isLoading: false
                });

            } else {
                alert(responseData.error);
            }
        });
    }

    getFiltersValue() {
        let code = document.getElementById('filterCode') ? document.getElementById('filterCode').value : '';
        let name = document.getElementById('filterName') ? document.getElementById('filterName').value : '';
        let zone = '';//document.getElementById('filterZone').value;
        let active = document.getElementById('filterActive') ? document.getElementById('filterActive').value : '';

        return [code, name, zone, active];
    }

    toggleModal(e) {
        let clientId = e.target.id;

        if (clientId) {
            this.getSoldInfo(clientId);
        }
        this.setState({
            showModal: !this.state.showModal
        });
    }

    getSoldInfo(clientId) {
        axios.get(`${apiConfig.baseUrl}/balances?clientId=${clientId}`)
            .then((response) => {
                let responseData = response.data;

                if (responseData.success) {
                    this.setState({soldComposition: responseData.solds});
                } else {
                    alert(responseData.error);
                }
            });
    }

    render() {
        let tableContent, tableSoldDecompose;
        if (this.state.isLoading) {
            tableContent = apiConfig.loader
        } else {
            let extraColumnHeading = '';
            if (this.props.loadedFrom === 'OrderDetails') {
                extraColumnHeading = <th></th>
            }

            tableContent = <Table className="tablesorter" responsive>
                <thead className="text-primary">
                <tr>
                    {extraColumnHeading}
                    <th>Id</th>
                    <th>Cod</th>
                    <th>Client</th>
                    <th>Sold</th>
                    <th>Telefon</th>
                    <th>Adresa</th>
                    <th>Oras</th>
                    <th>Judet</th>
                    <th>Zona</th>
                    <th>Discount</th>
                    <th>Agent</th>
                    <th>Actiuni</th>
                </tr>
                </thead>
                <tbody>
                {
                    this.state.clients.map(client => {
                        let optionalColumn = '';
                        if (this.props.loadedFrom === 'OrderDetails') {
                            optionalColumn =
                                <td>
                                    <Button className="btn-fill" color="primary"
                                            onClick={this.props.handleClientSelect}
                                            id={client.client}
                                            data-code={client.cod}
                                            data-name={client.customer}
                                    >
                                        Selecteaza
                                    </Button>
                                </td>
                        }

                        return (
                            <tr key={client.id}>
                                {optionalColumn}
                                <td>
                                    <a href={`client/interact?clientId=${client.client}&phone=${client.tel1}&email=erik.henning@ithit.ro&clientName=${client.customer}`}>
                                        {client.client}
                                    </a>
                                </td>
                                <td>{client.cod}</td>
                                <td>
                                    <a href={`client/interact?clientId=${client.client}&phone=${client.tel1}&email=erik.henning@ithit.ro&clientName=${client.customer}`}>
                                        {client.customer}
                                    </a>
                                </td>

                                <td>{client.sold}</td>
                                <td>{client.tel1}</td>
                                <td>{client.adresa}</td>
                                <td>{client.oras}</td>

                                <td>{client.judet}</td>
                                <td>{client.zona}</td>
                                <td>{client.discount}</td>
                                <td>{client.agent}</td>
                                <td>
                                    <Button outline id={client.client} className="btn-fill" color="primary"
                                            onClick={this.toggleModal}>
                                        Vizualizare sold
                                    </Button>
                                    <br/>
                                    <a outline
                                       href={`client/edit?id=${client.client}&cod=${client.cod}&adresa=${client.adresa}&cod_postal=${client.cod_postal}&oras=${client.oras}&judet=${client.judet}&zona=${client.zona}&discount=${client.discount}&max_discount=${client.max_discount}&tip_plata=${client.tip_plata}&comentarii=${client.comentarii}&tel1=${client.tel1}&nume=${client.customer}`}
                                       id={client.client}
                                       className="btn-fill"
                                       color="primary" onClick={this.toggleModal}>
                                        Editeaza fisa client
                                    </a>

                                </td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </Table>
        }

        return (
            <div className="content">
                <Modal size={"lg"} isOpen={this.state.showModal} toggle={this.toggleModal} style={{opacity: 1}}>
                    <ModalHeader style={{backgroundColor: '#525f7f'}} className={'text-primary'}>
                        Vizualizare sold
                    </ModalHeader>
                    <ModalBody style={{backgroundColor: '#525f7f'}}>
                        <Table className="tablesorter" responsive >
                            <thead className="text-dark">
                            <tr>
                                <th>Document</th>
                                <th>Data document</th>
                                <th>Scadenta</th>
                                <th>Valoare Document</th>
                                <th>Valoare Platita</th>
                                <th>Valoare Neplatita</th>
                                <th>Agent</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.soldComposition.map(document => {
                                    // console.log(document)

                                        return (
                                            <tr key={document.findoc}>
                                                <td>{document.findoc}</td>
                                                <td>{document.dataDocument}</td>
                                                <td>{document.scadenta}</td>
                                                <td>{document.valoareDocument}</td>
                                                <td>{document.valoarePlatita}</td>
                                                <td>{document.valoareNeplatita}</td>
                                                <td>{document.agent}</td>
                                            </tr>
                                        )
                                    }
                                )
                            }
                            </tbody>
                        </Table>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn-fill" color="primary" onClick={this.addArticle}>
                            Adauga
                        </Button>
                    </ModalFooter>
                </Modal>

                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <ClientsFilter colSize="12" handleSubmit={this.handleSubmit}/>
                                <CardTitle tag="h4"> Clienti </CardTitle>
                            </CardHeader>
                            <CardBody>
                                {tableContent}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="pr-md-1" md={{size: 2, offset: 8}}>
                        <PaginationComponent totalItems={this.state.totalItems}
                                             pageSize={this.PAGESIZE}
                                             onSelect={this.handleSelected}/>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Clients;
