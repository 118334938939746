
import React from "react";
// react plugin used to create google maps
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker
} from "react-google-maps";

// reactstrap components
import {Card, CardHeader, CardBody, Row, Col} from "reactstrap";
import SendOrder from "../components/Tabs/SendOrder";
import OrderDetails from "../components/Tabs/OrderDetails";

class EditOrder extends React.Component {
    constructor(props) {
        super(props);
        this.urlParams = new URLSearchParams(this.props.location.search);

        this.state = {
            orderId: this.urlParams.get('orderId')
        }
    }

    render() {
        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card className="card-plain">
                                <CardHeader>Editeaza comanda</CardHeader>
                                <CardBody>
                                    <OrderDetails colSize="12" orderId={this.state.orderId}></OrderDetails>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default EditOrder;
