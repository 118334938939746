/*eslint-disable*/
import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// reactstrap components
import {
    Row,
    Card,
    CardHeader,
    CardBody,
    Form,
    Col,
    FormGroup,
    Input, CardFooter, Button
} from "reactstrap";

import {BsCamera} from "react-icons/bs";
import FileInputComponent from 'react-file-input-previews-base64'

class FileInput extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Row>
                <Col md={this.props.colSize}>
                    <FormGroup>
                        <FileInputComponent
                            labelText={`Incarca poza ${this.props.id}`}
                            labelStyle={{fontSize:12}}
                            multiple={true}
                            callbackFunction={(file_arr)=>{this.props.callbackFunction(file_arr)}}
                            accept="image/*;capture=camera"
                            id={`file${this.props.id}`}
                        />

                    </FormGroup>
                </Col>
            </Row>
        );
    }
}

export default FileInput;
