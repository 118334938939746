/*eslint-disable*/
import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// reactstrap components
import {
    Row,
    Card,
    CardHeader,
    CardBody,
    Form,
    Col,
    FormGroup,
    Input, CardFooter, Button, Table
} from "reactstrap";
import axios from "axios";
import apiConfig from "../../apiConfig";
import Moment from "moment";

class LoadOrders extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            cars: [],
            orders: [],
            isLoading: true,
        }
        this.handleMailSubmit = this.handleMailSubmit.bind(this);
    }

    componentDidMount() {
        this.setState({
            isLoading: false
        });
        this.fetchOrdersData()
    }

    handleMailSubmit(event) {
        event.preventDefault();

        // Ca sa trimit la fiecare client fac un for dupa fiecare mail / order unic
        let requestBody = {
            "receiver_name": this.state.orders[0].nume,
            "receiver_email": this.state.orders[0].client_email,
            "subject": `[Express Instal] Comanda in curs de livrare`,
            "body": `Buna ziua, soferul nostru a plecat cu comanda XYZ. (va fi template HTML)`
        }


        axios.post(`${apiConfig.baseUrl}/notification/mail/send`, requestBody).then((response) => {
            let responseData = response.data;

            if (responseData.success) {
                alert('Ati trimis cu succes mail-ul catre client!')
            } else {
                alert(responseData.error);
            }
        });
    }

    fetchOrdersData() {
        this.setState({
            isLoading: true
        });

        let url = `${apiConfig.baseUrl}/orders`;
        let body = {
            'filters': `CCCPrepared=1&CCCLoaded=0`,
            'start': 0  ,
            'limit': 2000
        };

        axios.post(`${url}`, body).then((response) => {
            let responseData = response.data;
            this.setState({orders: []});

            if (responseData.success) {
                this.setState({
                    totalItems: responseData.totalCount,
                    orders: responseData.orders,
                    isLoading: false
                });
            } else {
                alert(responseData.error);
            }
        });
    }

    render() {
        let tableContent;

        if (this.state.isLoading) {
            tableContent = apiConfig.loader
        } else {
            tableContent = <Table className="tablesorter" responsive>
                <thead className="text-primary">
                <tr>
                    <th>Nume client</th>
                    <th>Email client</th>
                    <th>Id comanda</th>
                    <th>Valoare</th>
                </tr>
                </thead>
                <tbody>
                {
                    this.state.orders.map(order => (
                        <tr key={order.document}>
                            <td>{order.nume}</td>
                            <td>{order.client_email}</td>
                            <td>
                                <a href={`/admin/gestionar/order/load?orderId=${order.tranzactie}`}>
                                    {order.document}
                                </a>

                            </td>
                            <td>{order.valoare}</td>
                        </tr>
                    ))
                }
                </tbody>
            </Table>
        }

        return (
            <Col md={this.props.colSize}>
                <Card>
                    <CardHeader>
                        <h5 className="title">Lista comenzi</h5>
                    </CardHeader>
                    <CardBody>
                        <Form>
                            <Row>
                                <Col md="12">
                                    Lista comenzi de incarcat:
                                    {tableContent}
                                </Col>
                            </Row>
                        </Form>
                    </CardBody>
                    <CardFooter>

                    </CardFooter>
                </Card>
            </Col>
        );
    }
}

export default LoadOrders;
