
import Dashboard from "views/Dashboard.js";
import Icons from "views/Icons.js";
import ClientOrder from "views/ClientOrder.js";
import ClientInteractions from "views/ClientInteractions.js";
import Rtl from "views/Rtl.js";
import Articles from "views/Articles.js";
import Typography from "views/Typography.js";
import UserProfile from "views/UserProfile.js";
import Reports from "./views/Reports";
import Orders from "./views/Orders";
import Clients from "./views/Clients";
import EditOrder from "./views/EditOrder";
import Login from "./views/Login";
import CreateOrder from "./views/CreateOrder";
import EditClient from "./views/EditClient";
import DriverInteractions from "./views/DriverInteractions";
import GestionarControl from "./views/GestionarControl";
import PrepareOrderView from "./views/PrepareOrderView";
import GestionarControlIncarcare from "./views/GestionarControlIncarcare";
import LoadOrderView from "./views/LoadOrderView";
import Inventory from "./views/Inventory";
import UnloadOrderView from "./views/UnloadOrderView";

let routes = [
    {
        path: "/gestionar/control",
        name: "Pregateste comanda",
        rtlName: "Pregateste comanda",
        icon: "tim-icons icon-bell-55",
        component: GestionarControl,
        layout: "/admin",
        role: ["gestionar"]
    },
    {
        path: "/gestionar/load",
        name: "Incarcare comanda",
        rtlName: "Incarcare comanda",
        icon: "tim-icons icon-bell-55",
        component: GestionarControlIncarcare,
        layout: "/admin",
        role: ["gestionar"]
    },
    {
        path: "/gestionar/inventory",
        name: "Inventar",
        rtlName: "Inventar",
        icon: "tim-icons icon-bell-55",
        component: Inventory,
        layout: "/admin",
        role: ["gestionar"]
    },
    {
        path: "/gestionar/order/prepare",
        name: "Pregateste comanda",
        rtlName: "Client Order",
        icon: "tim-icons icon-pin",
        component: PrepareOrderView,
        layout: "/admin",
        role: ["gestionar"],
        invisible: true,
    },
    {
        path: "/gestionar/order/load",
        name: "Incarcare comanda",
        rtlName: "Client Order",
        icon: "tim-icons icon-pin",
        component: LoadOrderView,
        layout: "/admin",
        role: ["gestionar"],
        invisible: true,
    },
    {
        path: "/sofer/order/unload",
        name: "Descarcare comanda",
        rtlName: "Client Order",
        icon: "tim-icons icon-pin",
        component: UnloadOrderView,
        layout: "/admin",
        role: ["sofer"],
        invisible: true,
    },
    {
        path: "/sofer/control",
        name: "Panou de control",
        rtlName: "Panou de control",
        icon: "tim-icons icon-bell-55",
        component: DriverInteractions,
        layout: "/admin",
        role: ["sofer"]
    },
    {
        path: "/dashboard",
        name: "Panou de control",
        rtlName: "Dashboard",
        icon: "tim-icons icon-chart-pie-36",
        component: Dashboard,
        layout: "/admin",
        role: ["agent"]
    },
    {
        path: "/clients",
        name: "Lista clienti",
        rtlName: "Comanda",
        icon: "tim-icons icon-pin",
        component: Clients,
        layout: "/admin",
        role: ["agent"]
    },
    {
        path: "/client/interact",
        name: "Interactiune client",
        rtlName: "Client Interaction",
        icon: "tim-icons icon-bell-55",
        component: ClientInteractions,
        layout: "/admin",
        invisible: true,
        role: ["agent", "sofer"]
    },
    {
        path: "/client/edit",
        name: "Editeaza client",
        rtlName: "Client Edit",
        icon: "tim-icons icon-bell-55",
        component: EditClient,
        layout: "/admin",
        invisible: true,
        role: ["agent"]
    },
    {
        path: "/orders",
        name: "Comenzi",
        rtlName: "Orders",
        icon: "tim-icons icon-single-02",
        component: Orders,
        layout: "/admin",
        role: ["agent"]
    },
    {
        path: "/order/edit",
        name: "Editeaza comanda",
        rtlName: "Client Order",
        icon: "tim-icons icon-pin",
        component: EditOrder,
        layout: "/admin",
        role: ["agent", "sofer"],
        invisible: true,
    },
    {
        path: "/order/new",
        name: "Adauga comanda",
        rtlName: "Create new order",
        icon: "tim-icons icon-pin",
        component: CreateOrder,
        layout: "/admin",
        role: ["agent"]
    },
    {
        path: "/login",
        name: "Login",
        rtlName: "Login",
        icon: "tim-icons icon-single-02",
        component: Login,
        layout: "/admin",
        invisible: true,
        standalone: true,
        role: ["agent"]
    },
    {
        path: "/articles",
        name: "Articles",
        rtlName: "Articole",
        icon: "tim-icons icon-single-02",
        component: Articles,
        layout: "/admin",
        role: ["agent"]
    },
];
export default routes;
