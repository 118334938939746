import React from "react";
// reactstrap components
import {Button, Col, Form, FormGroup, Input, Row} from "reactstrap";
import apiConfig from "../../apiConfig";
import axios from "axios";
import logo from "assets/img/express-logo.png";
import Cookies from 'js-cookie'

class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultEmailValue: Cookies.get('lastLoggedEmail') ? Cookies.get('lastLoggedEmail') : '',
        }
        // this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        if (Cookies.get('email'))   {
            let role = Cookies.get('role');

            if (role == 'agent') {
                window.location.href = '/';
            }
            else if (role == 'gestionar')   {
                window.location.href = '/admin/gestionar/control'
            }
            else if (role == 'sofer')   {
                window.location.href = '/admin/sofer/control'
            }
        }
    }

    handleSubmit(event) {
        console.log('handle submit');
        event.preventDefault();
        let email = document.getElementById('email').value;
        let password = document.getElementById('parola').value;

        let requestBody = {
            'email': email,
            'password': password
        }

        console.log(requestBody);

        axios.post(`${apiConfig.baseUrl}/auth/login`, requestBody).then((response) => {
            let responseData = response.data;

            if (responseData.hasOwnProperty('access_token')) {
                let expire_in_days = responseData.expires_in / 7;

                Cookies.set('token', responseData.access_token, { expires: expire_in_days })
                Cookies.set('softone_id', responseData.softone_id, { expires: expire_in_days })
                Cookies.set('name', responseData.name, { expires: expire_in_days })
                Cookies.set('role', responseData.role, { expires: expire_in_days })
                Cookies.set('email', responseData.email, { expires: expire_in_days })


                apiConfig.header['Authorization'] = `${responseData.token_type} ${responseData.access_token}`;

                Cookies.set('lastLoggedEmail', responseData.email, {expires: 1000})
                if (responseData.role == 'agent') {
                    window.location.href = '/';
                }
                else if (responseData.role == 'gestionar')   {
                    window.location.href = '/admin/gestionar/control'
                }
                else if (responseData.role == 'sofer')   {
                    window.location.href = '/admin/sofer/control'
                }
            } else {
                alert("User sau parola gresita");
            }
        })
            .catch((err) => alert("User sau parola gresita"));
    }

    render() {
        return (
            <div className="content">
                <Row>
                    <Col className="pr-md-5" md={{size: 6, offset: 3}}>
                        <img src={logo} alt="ithit-logo" style={{maxWidth: 120, maxHeight: 120}}/>
                    </Col>
                </Row>
                <Form onSubmit={this.handleSubmit} id = "loginForm">
                    <Row>
                        <Col className="pr-md-1" md={{size: 6, offset: 3}}>
                            <Row>
                                <Col md="12">
                                    <FormGroup>
                                        <label htmlFor="email">Email</label>
                                        <Input type="text" name="email" id="email" defaultValue={this.state.defaultEmailValue}/>
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <FormGroup>
                                        <label htmlFor="parola">Parola</label>
                                        <Input type="password" name="parola" id="parola"/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="pr-md-1" md="3">
                                    <FormGroup>
                                        <label></label>
                                        <Button className="btn-fill" color="primary" type="submit">
                                            Logare
                                        </Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

export default LoginForm;
