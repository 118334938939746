import React from "react";
// reactstrap components
import {Button, Col, Form, FormGroup, Input, Row} from "reactstrap";

class ClientsFilter extends React.Component {

    render() {
        return (
            <div className="content">
                <Form onSubmit={this.props.handleSubmit}>
                    <Row>
                        <Col className="pr-md-1" md={this.props.colSize}>
                            <Row>
                                <Col md="4">
                                    <FormGroup>
                                        <label htmlFor="filterName">Cod</label>
                                        <Input type="text" name="filterCode" id="filterCode"/>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label htmlFor="filterName">Nume</label>
                                        <Input type="text" name="filterName" id="filterName"/>
                                    </FormGroup>
                                </Col>
                                {/*<Col md="4">*/}
                                {/*    <FormGroup>*/}
                                {/*        <label htmlFor="filterZone">Zona geografica</label>*/}
                                {/*        <Input type="select" name="filterZone" id="filterZone">*/}
                                {/*            <option value=""> De preluat zonele din S1</option>*/}
                                {/*            <option>Muntenia</option>*/}
                                {/*            <option>Bucuresti</option>*/}
                                {/*            <option>Ardeal</option>*/}
                                {/*            <option>Moldova</option>*/}
                                {/*        </Input>*/}
                                {/*    </FormGroup>*/}
                                {/*</Col>*/}
                                <Col md="3">
                                    <FormGroup>
                                        <label htmlFor="filterActive">Activ</label>
                                        <Input type="select" name="select" id="filterActive">
                                            <option value="1">Da</option>
                                            <option value="0">Nu</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="pr-md-1" md="3">
                        <FormGroup>
                            <label></label>
                            <Button className="btn-fill" color="primary" type="submit">
                                Filter
                            </Button>
                        </FormGroup>
                        </Col>
                    </Row>

                </Form>
            </div>
        );
    }
}

export default ClientsFilter;
