
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import {Line, Bar} from "react-chartjs-2";

// reactstrap components
import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Label,
    FormGroup,
    Input,
    Table,
    Row,
    Col,
    UncontrolledTooltip
} from "reactstrap";

// core components
import {
    chartExample2,
    chartExample4
} from "variables/charts.js";
import apiConfig from "../apiConfig";
import axios from "axios";
import Cookies from 'js-cookie';

class Dashboard extends React.Component {
    CALCULATION_YEAR = '2022'

    constructor(props) {
        super(props);

        this.state = {
            lineChartData: '',
            totalSales: 0,
        }
    }

    calculateTotalSales(salesRecord) {
        let totalSales = 0;

        for (let index in salesRecord) {
            totalSales = totalSales + parseFloat(salesRecord[index].Vanzari);
        }

        return totalSales;
    }

    sortSalesListByMonthAndYear(salesRecord, year) {
        salesRecord = salesRecord.filter(sale => sale.AnFiscal === year);

        return salesRecord.sort((sale1, sale2) => {
            let lunaSale1 = parseInt(sale1.Luna);
            let lunaSale2 = parseInt(sale2.Luna);

            if (lunaSale1 < lunaSale2) {
                return -1;
            }
            if (lunaSale1 > lunaSale2) {
                return 1;
            }
            return 0;
        });
    };

    componentDidMount() {
        axios.get(`${apiConfig.baseUrl}/agents/getSales?agentId=${Cookies.get('softone_id')}`).then((response) => {
            let responseData = response.data;

            if (responseData.success) {
                let orderedSalesByYear = this.sortSalesListByMonthAndYear(responseData.agentSales, this.CALCULATION_YEAR);

                this.setState({
                    totalSales: this.calculateTotalSales(orderedSalesByYear),
                    lineChartData: {
                        labels: orderedSalesByYear.map(sale => sale.Luna),
                        datasets: [
                            {
                                label: 'Target',
                                data: [60000, 60000, 60000, 60000, 60000, 60000, 60000, 60000, 60000, 60000, 60000, 60000],
                                backgroundColor: [
                                    'rgba(0, 200, 50, 0.2)'

                                ],
                                borderColor: [
                                    'green'

                                ],
                                borderWidth: 1,
                                pointBackgroundColor: "green",
                            },
                            {
                                label: 'Realizat',
                                data: orderedSalesByYear.map(sale => sale.Vanzari),
                                backgroundColor: [
                                    'rgba(0, 0, 200, 0.2)'
                                ],
                                borderColor: [
                                    'cyan'
                                ],
                                borderWidth: 1,
                                pointBackgroundColor: "cyan",
                            }
                        ]
                    },
                    options: {
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true
                                }
                            }]
                        }
                    }
                });
            }
        })


    }

    render() {
        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card className="card-chart">
                                <CardHeader>
                                    <Col md="6">
                                        <span className="card-category">Target - de luat din softone</span>
                                        <CardTitle tag="h3">
                                            <i className="tim-icons icon-bell-55 text-info"/>
                                            763,215
                                        </CardTitle>
                                    </Col>
                                    <Col md="6">
                                        <span className="card-category">Realizat</span>
                                        <CardTitle tag="h3">
                                            <i className="tim-icons icon-bell-55 text-info"/>
                                            {this.state.totalSales}
                                        </CardTitle>
                                    </Col>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart-area">
                                        <Line
                                            data={this.state.lineChartData}
                                            options={{maintainAspectRatio: true}}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default Dashboard;
