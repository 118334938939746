import React from "react";
// reactstrap components
import {Button, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import DatePicker from "reactstrap-date-picker";

class OrdersFilter extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="content">
                <Form onSubmit={this.props.handleSubmit}>
                    <Row>
                        <Col className="pr-md-1" md={this.props.colSize}>
                            <Row>
                                <Col md="6">
                                    <FormGroup>
                                        <label htmlFor="filterDateStart">Data inceput</label>

                                        <DatePicker id="filterDateStart"
                                                    value={this.props.defaultStartDate}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label htmlFor="filterDateEnd">Data sfarsit</label>

                                        <DatePicker id="filterDateEnd"
                                                    value={this.props.defaultEndDate}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <FormGroup>
                                        <label htmlFor="filterCode">Cod comanda</label>
                                        <Input type="text" name="filterCode" id="filterCode"/>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label htmlFor="filterActive">Activ</label>
                                        <Input type="select" name="select" id="filterActive">
                                            <option value="1">Da</option>
                                            <option value="0">Nu</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="pr-md-1" md="3">
                            <FormGroup>
                                <label></label>
                                <Button className="btn-fill" color="primary" type="submit">
                                    Filter
                                </Button>
                            </FormGroup>
                        </Col>
                    </Row>

                </Form>
            </div>
        );
    }
}

export default OrdersFilter;
