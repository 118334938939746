/*eslint-disable*/
import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// reactstrap components
import {
    Row,
    Card,
    CardHeader,
    CardBody,
    Form,
    Col,
    FormGroup,
    Input, CardFooter, Button
} from "reactstrap";

import {gapi} from 'gapi-script';
import {BsCamera} from "react-icons/bs";
import GoogleAuthorize from 'react-google-authorize';

class GoogleDriveUpload extends React.Component {
    SCOPE = 'https://www.googleapis.com/auth/drive.file';
    discoveryUrl = 'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest';

    GOOGLE_UPLOAD_URL = 'https://www.googleapis.com/upload/drive/v3/files?uploadType=media'

    state = {
        name: '',
        googleAuth: ''
    }

    componentDidMount() {
        var script = document.createElement('script');
        script.onload = this.handleClientLoad;
        script.src = "https://apis.google.com/js/api.js";
        document.body.appendChild(script);
    }


    initClient = () => {
        try {
            window.gapi.client.init({
                'apiKey': "AIzaSyD7_6QgyTIuRI3iLdAuE5bLGmhD6a1GKZw",
                'clientId': "1078366165388-dip3pkq2i3c2c82n3gmrk9jc6je4t18e.apps.googleusercontent.com",
                'scope': this.SCOPE,
                'discoveryDocs': [this.discoveryUrl]
            }).then(() => {
                this.setState({
                    googleAuth: window.gapi.auth2.getAuthInstance()
                })
                this.state.googleAuth.isSignedIn.listen(this.updateSigninStatus);
                document.getElementById('signin-btn').addEventListener('click', this.signInFunction);
                document.getElementById('signout-btn').addEventListener('click', this.signOutFunction);

            });
        } catch (e) {
            console.log(e);
        }
    }


    signInFunction = () => {
        this.state.googleAuth.signIn();
        this.updateSigninStatus()
    }

    signOutFunction = () => {
        this.state.googleAuth.signOut();
        this.updateSigninStatus()
    }

    updateSigninStatus = () => {
        this.setSigninStatus();
    }


    setSigninStatus = async () => {
        var user = this.state.googleAuth.currentUser.get();
        // console.log(user)
        if (user.uc == null) {
            this.setState({
                name: ''
            });

            // console.log('INTRA IN IF')
        } else {
            // console.log('INTRA IN ELSE')
            var isAuthorized = user.hasGrantedScopes(this.SCOPE);
            if (isAuthorized) {
                this.setState({
                    name: user.Qt.zu
                });
                const boundary = 'foo_bar_baz'
                const delimiter = "\r\n--" + boundary + "\r\n";
                const close_delim = "\r\n--" + boundary + "--";
                var fileName = 'mychat123';
                var fileData = 'this is a sample data';
                var contentType = 'text/plain'
                var metadata = {
                    'name': fileName,
                    'mimeType': contentType
                };

                var multipartRequestBody =
                    delimiter +
                    'Content-Type: application/json; charset=UTF-8\r\n\r\n' +
                    JSON.stringify(metadata) +
                    delimiter +
                    'Content-Type: ' + contentType + '\r\n\r\n' +
                    fileData + '\r\n' +
                    close_delim;

                // console.log(multipartRequestBody);
                var request = window.gapi.client.request({
                    'path': 'https://www.googleapis.com/upload/drive/v3/files',
                    'method': 'POST',
                    'params': {'uploadType': 'multipart'},
                    'headers': {
                        'Content-Type': 'multipart/related; boundary=' + boundary + ''
                    },
                    'body': multipartRequestBody
                });

                // console.log(request);
                request.execute(function (file) {
                    // console.log(file)
                });
            }
        }
    }

    handleClientLoad = () => {
        window.gapi.load('client:auth2', this.initClient);
    }

    render() {

        return (
            <Row>
                <Col md={this.props.colSize}>
                    <FormGroup>
                        <div>UserName: <strong>{ this.state.name}</strong></div>
                        <button id="signin-btn">Sign In</button>
                        <button id="signout-btn">Sign Out</button>
                    </FormGroup>
                </Col>
            </Row>
        );
    }
}

export default GoogleDriveUpload;
