/*eslint-disable*/
import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// reactstrap components
import {
    Row,
    Card,
    CardHeader,
    CardBody,
    Form,
    Col,
    FormGroup,
    Input, CardFooter, Button, Table
} from "reactstrap";
import axios from "axios";
import apiConfig from "../../apiConfig";
import Moment from "moment";
import CameraInputs from "./CameraInputs";
import SignatureConfirmation from "../Parts/SignatureConfirmation";
import {BsTextarea} from "react-icons/all";
import Cookies from "js-cookie";

class FinishDrive extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            orders: [],
            isLoading: true,
        };
        this.handleMailSubmit = this.handleMailSubmit.bind(this);
        this.handleCarChange = this.handleCarChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.setState({
            isLoading: false
        });

        if (this.props.orderId) {
            this.setState({
                isLoading: true
            })
            let requestBody = {
                filters: ""
            }

            axios.post(`${apiConfig.baseUrl}/orders/details/${this.props.orderId}`, requestBody).then((response) => {
                let responseData = response.data;

                if (responseData.success) {
                    this.setOrderFields(responseData.orderDetails, responseData.orderItems)
                } else {
                    alert(responseData.error);
                }
            });
        }
    }

    handleCarChange(event) {
        this.fetchOrdersData()
    }

    setOrderFields(orderDetails, orderItems) {
        console.warn(orderDetails)
        this.setState({
            data: orderDetails.data,
            document: orderDetails.document,
            filiala: orderDetails.filiala,
            cod: orderDetails.cod,
            companie: orderDetails.nume,
            valoare: orderDetails.valoare,
            moneda: orderDetails.moneda,
            anulat: orderDetails.anulat,
            observatii: orderDetails.observatii,
            orderArticles: orderItems,
            isLoading: false,
        });
    }

    handleMailSubmit(event) {
        event.preventDefault();

        // Ca sa trimit la fiecare client fac un for dupa fiecare mail / order unic
        let requestBody = {
            "receiver_name": this.state.orders[0].nume,
            "receiver_email": this.state.orders[0].client_email,
            "subject": `[Express Instal] Comanda in curs de livrare`,
            "body": `Buna ziua, soferul nostru a plecat cu comanda XYZ. (va fi template HTML)`
        }

        console.log(requestBody);
        axios.post(`${apiConfig.baseUrl}/notification/mail/send`, requestBody).then((response) => {
            let responseData = response.data;

            if (responseData.success) {
                alert('Ati trimis cu succes mail-ul catre client!')
            } else {
                alert(responseData.error);
            }
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        let updatedFields = {
            "SERIES": '7055',
            "TRNDATE": this.state.data,
            "FINCODE": this.state.document,
            "TRDR": this.state.clientId,
            "SUMAMNT": this.state.valoare,
            "SOCURRENCY": this.state.moneda,
            "ISCANCEL": this.state.anulat,
            "COMMENTS": this.state.observatii,
            "SALESMAN": Cookies.get('softone_id'),
            "CCCLivrat": 1,
        };

        let extralines = [];

        this.state.orderArticles.forEach(article => {
            let articleLine = {
                "MTRL": article.articol,
                "PRICE": article.pret_ridicata,
                "QTY1": article.cantitate,
            };

            extralines.push(articleLine);
        });

        let url;


        if (this.props.orderId) {
            url = `${apiConfig.baseUrl}/orders/update?orderId=${this.props.orderId}`;
        } else {
            url = `${apiConfig.baseUrl}/orders/insert`;
        }

        let requestBody = {
            fields: updatedFields,
            extraLines: extralines,
        }

        console.log(requestBody);

        console.warn(url);

        axios.post(url, requestBody).then((response) => {
            let responseData = response.data;

            if (responseData.success) {
                console.log(responseData);
                alert('Comanda a fost salvata cu succes');
                window.location.href = '/admin/orders';
            } else {
                alert(responseData.error);
            }
        });
    }

    fetchOrdersData() {
        this.setState({
            isLoading: true
        });

        let url = `${apiConfig.baseUrl}/orders`;
        let body = {
            'filters': ``,
            'start': 0,
            'limit': 2000
        };

        axios.post(`${url}`, body).then((response) => {
            let responseData = response.data;
            this.setState({orders: []});

            if (responseData.success) {
                this.setState({
                    totalItems: responseData.totalCount,
                    orders: responseData.orders,
                    isLoading: false
                });
            } else {
                alert(responseData.error);
            }
        });
    }

    render() {
        return (
            <Col md={this.props.colSize}>
                <Card>
                    <CardHeader>
                        <h5 className="title">Finalizeaza livrare</h5>
                    </CardHeader>
                    <CardBody>
                        <Form onSubmit={this.handleSubmit}>
                            <Row>
                                <Col className="pr-md-1" md="6">
                                    <FormGroup>
                                        <label>Introduceti ID-ul comenzii</label>
                                        <Input
                                            defaultValue={this.props.orderId}
                                            id="order_id"
                                            type="text"
                                        >
                                        </Input>
                                    </FormGroup>
                                </Col>

                                <CameraInputs colSize="12"
                                              clientEmail={`test@company.com`}
                                              clientName={`Companie Test`}/>
                            </Row>

                            <Row>
                                <Col className="pr-md-1" md="6">
                                    <FormGroup>
                                        <label>Comentarii</label>
                                        <Input
                                            id="comentarii"
                                            type="textarea"
                                        >
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="pr-md-1" md="6">
                                    <FormGroup>
                                        <label htmlFor="filterGroup">Ridicare instrumente de plata</label>
                                        <Input type="select" name="select" id="filterGroup">
                                            <option value="1"> Am ridicat BO</option>
                                            <option value="2">Nu este cazul</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <SignatureConfirmation colSize="12"/>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <Button className="btn-fill" color="primary" type="submit"
                                            style={{display: Cookies.get('role') == 'sofer' ? 'block' : 'none'}}>
                                        Trimite
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                        <Button className="btn-fill" color="primary" type="submit" id = {`${this.props.orderId}_sendmail`} onClick={this.props.handleMailSubmit}>
                            Marfa livrata? Notifica client
                        </Button>
                    </CardBody>
                    <CardFooter>

                    </CardFooter>
                </Card>
            </Col>
        );
    }
}

export default FinishDrive;
