/*eslint-disable*/
import React from "react";
// used for making the prop types of this component
// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import axios from "axios";
import apiConfig from "../../apiConfig";
import { PDFExport, savePDF } from '@progress/kendo-react-pdf';
import Cookies from 'js-cookie';

class ClientDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cod: this.props.cod,
            nume: this.props.nume,
            tel1: this.props.tel1,
            adresa: this.props.adresa,
            cod_postal: this.props.cod_postal,
            oras: this.props.oras,
            judet: this.props.judet,
            zona: this.props.zona,
            discount: this.props.discount,
            max_discount: this.props.max_discount,
            tip_plata: this.props.tip_plata,
            comentarii: this.props.comentarii,
            sold_neincasat: 'LOADING',
            bo_asteptare: 'LOADING',
            neacoperit_bo: 'LOADING',
            pdfExport: '',
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.getSoldInfo(this.props.clientId)
    }

    handleSubmit(event) {
        event.preventDefault();

        let updatedFields = {
            "NAME": this.state.nume,
            "PHONE01": this.state.tel1,
            "ADDRESS": this.state.adresa,
            "ZIP": this.state.cod_postal,
            "CITY": this.state.oras,
            "DISTRICT1": this.state.judet,
            "AREAS": this.state.zona,
            "DISCOUNT": this.state.discount,
            "MAXPRCDISC": this.state.max_discount,
            "SALESMAN": Cookies.get('softone_id'),
            "PAYMENT": this.state.tip_plata,
            "REMARKS": this.state.comentarii
        };
        let url;

        if (this.props.clientId) {
            url = `${apiConfig.baseUrl}/clients/update?clientId=${this.props.clientId}`;
        } else {
            url = `${apiConfig.baseUrl}/clients/insert`;
        }

        let requestBody = {
            fields: updatedFields
        }

        axios.post(url, requestBody).then((response) => {
            let responseData = response.data;

            if (responseData.success) {
                alert('Clientul a fost salvat cu succes');
                window.location.href = '/admin/clients';
            } else {
                alert(responseData.error);
            }
        });
    }

    getSoldInfo(clientId) {
        axios.get(`${apiConfig.baseUrl}/balances?clientId=${clientId}`).then((response) => {
            let responseData = response.data;

            if (responseData.success) {
                let sold = responseData.solds[0]
                console.log(sold);
                // this.setState({
                //     sold_neincasat: sold['sold_neincasat'],
                //     bo_asteptare: sold['bo_asteptare'],
                //     neacoperit_bo: sold['neacoperit_bo'],
                // });
            } else {
                alert(responseData.error);
            }
        });
    }

    render() {
        const pdfExportComponent = React.createRef();
        let formContent;
        if (this.state.isLoading) {
            formContent = apiConfig.loader
        } else {
            formContent = <Form onSubmit={this.handleSubmit}>
                <Row>
                    <Col className="pr-md-1" md="6">
                        <FormGroup>
                            <label htmlFor="code">Cod</label>
                            <Input type="text" id="code" value={this.state.cod}/>
                        </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">

                        <FormGroup>
                            <label htmlFor="nume">Nume client</label>
                            <Input type="text" id="nume" defaultValue={this.state.nume} onChange={(e) => {
                                this.setState({nume: e.target.value});
                            }}/>
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col className="pr-md-1" md="12">

                        <FormGroup>
                            <label htmlFor="tel1">Tel</label>
                            <Input type="text" id="tel1" defaultValue={this.state.tel1} onChange={(e) => {
                                this.setState({tel1: e.target.value});
                            }}/>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="pr-md-1" md="12">
                        <FormGroup>
                            <label htmlFor="adresa">Adresa</label>
                            <Input type="text" id="adresa" defaultValue={this.state.adresa} onChange={(e) => {
                                this.setState({adresa: e.target.value});
                            }}/>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="pr-md-1" md="6">
                        <FormGroup>
                            <label htmlFor="cod_postal">Cod postal</label>
                            <Input type="text" id="cod_postal" defaultValue={this.state.cod_postal} onChange={(e) => {
                                this.setState({cod_postal: e.target.value});
                            }}/>
                        </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">

                        <FormGroup>
                            <label htmlFor="oras">Oras</label>
                            <Input type="text" id="oras" defaultValue={this.state.oras} onChange={(e) => {
                                this.setState({oras: e.target.value});
                            }}/>
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col className="pr-md-1" md="6">
                        <FormGroup>
                            <label htmlFor="judet">Judet</label>
                            <Input type="text" id="judet" defaultValue={this.state.judet} onChange={(e) => {
                                this.setState({judet: e.target.value});
                            }}/>
                        </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                        <FormGroup>
                            <label htmlFor="zona">Zona</label>
                            <Input type="text" id="zona" defaultValue={this.state.zona} onChange={(e) => {
                                this.setState({zona: e.target.value});
                            }}/>
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col className="pr-md-1" md="6">
                        <FormGroup>
                            <label htmlFor="discount">Discount</label>
                            <Input type="text" id="discount" defaultValue={this.state.discount} onChange={(e) => {
                                this.setState({discount: e.target.value});
                            }}/>
                        </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                        <FormGroup>
                            <label htmlFor="max_discount">Discount maxim</label>
                            <Input type="text" id="max_discount" defaultValue={this.state.max_discount}
                                   onChange={(e) => {
                                       this.setState({max_discount: e.target.value});
                                   }}/>
                        </FormGroup>
                    </Col>
                </Row>
                {/*<Row>*/}
                {/*    <Col className="pr-md-1" md="12">*/}
                {/*        <FormGroup>*/}
                {/*            <label htmlFor="sold_neincasat">Sold neincasat</label>*/}
                {/*            <Input type="text" id="sold_neincasat" value={this.state.sold_neincasat}/>*/}
                {/*        </FormGroup>*/}
                {/*    </Col>*/}
                {/*    <Col className="pr-md-1" md="12">*/}
                {/*        <FormGroup>*/}
                {/*            <label htmlFor="bo_asteptare">BO in asteptare</label>*/}
                {/*            <Input type="text" id="bo_asteptare" value={this.state.bo_asteptare}/>*/}
                {/*        </FormGroup>*/}
                {/*    </Col>*/}
                {/*    <Col className="pr-md-1" md="12">*/}
                {/*        <FormGroup>*/}
                {/*            <label htmlFor="neacoperit_bo">Neacoperit de BO</label>*/}
                {/*            <Input type="text" id="neacoperit_bo" value={this.state.neacoperit_bo}/>*/}
                {/*        </FormGroup>*/}
                {/*    </Col>*/}
                {/*</Row>*/}

                <Row>
                    <Col className="pr-md-1" md="12">
                        <FormGroup>
                            <label htmlFor="tip_plata">Tip plata</label>
                            <Input type="select" id="tip_plata" defaultValue={this.state.tip_plata} onChange={(e) => {
                                this.setState({tip_plata: e.target.value});
                            }}>
                                <option value="3"> Contract</option>
                                <option value="4"> Plata avans</option>
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col className="pr-md-1" md="12">
                        <FormGroup>
                            <label htmlFor="comentarii">Comentarii</label>
                            <Input type="text" id="comentarii" defaultValue={this.state.comentarii} onChange={(e) => {
                                this.setState({comentarii: e.target.value});
                            }}/>
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Button className="btn-fill" color="primary" type="submit">
                        Salveaza
                    </Button>
                </Row>

            </Form>
            ;
        }

        const  handleExportWithComponent  = (event) => {
            this.setState({pdfExport: pdfExportComponent.current.save()});
        }

        return (
            <Col md={this.props.colSize}>

                <div>
                    <PDFExport  ref={pdfExportComponent}  paperSize="A4">
                    <Card>
                        <CardHeader>
                            <h5 className="title">Detalii client</h5>
                        </CardHeader>
                        <CardBody>
                            {formContent}

                        </CardBody>
                    </Card>
                    </PDFExport>
                    <Button primary={true} onClick={handleExportWithComponent}>Export with Component</Button>
                </div>
            </Col>
        );
    }
}

export default ClientDetails;
