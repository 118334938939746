import React from "react";
// react plugin for creating notifications over the dashboard
// reactstrap components
import {Button, Card, CardBody, CardTitle, Col, Form, Input, Row, Table} from "reactstrap";
import SendMail from "../components/Tabs/SendMail";
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import CameraInputs from "../components/Tabs/CameraInputs";
import GoogleDriveUpload from "../components/Parts/GoogleDriveUpload";
import axios from "axios";
import apiConfig from "../apiConfig";
import Cookies from 'js-cookie';

class ClientInteractions extends React.Component {
    constructor(props) {
        super(props);
        this.urlParams = new URLSearchParams(this.props.location.search);

        this.state = {
            clientId: this.urlParams.get('clientId'),
            phone: this.urlParams.get('phone'),
            email: this.urlParams.get('email'),
            clientName: this.urlParams.get('clientName'),
            content: <SendMail mailTo={this.urlParams.get('email')}
                               clientName={this.urlParams.get('clientName')}
                               clientId={this.urlParams.get('clientId')}
                               colSize="12"/>,
            displayCamera: '',
            clientInteractions: [],
        };

        console.warn(this.state.email)
    }

    componentDidMount() {
        this.getInteractionsInfo();
    }

    getInteractionsInfo() {
        axios.get(`${apiConfig.baseUrl}/reports/getInteractionsForClientAndUser/${Cookies.get('softone_id')}/${this.state.clientId}`).then((response) => {
            let responseData = response.data;

            if (responseData.success) {
                this.setState({
                    clientInteractions: responseData.interactions.sort((a,b) => (a.date < b.date) ? 1 : ((b.date < a.date) ? -1 : 0))
                });
            } else {
                alert(responseData.error);
            }
        });
    }

    render() {
        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardBody>
                                    <div className="places-buttons">
                                        <Row>
                                            <Col className="ml-auto mr-auto text-center" md="6">
                                                <CardTitle tag="h4">
                                                    Interactiuni client
                                                </CardTitle>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="ml-auto mr-auto" lg="8">
                                                <Row>
                                                    <Col md="3">
                                                        <Button
                                                            block
                                                            color="primary"
                                                            onClick={() => this.setState({
                                                                content: <SendMail mailTo={this.state.email}
                                                                                   clientId={this.state.clientId}
                                                                                   clientName={this.state.clientName}
                                                                                   colSize="12"/>
                                                            })}
                                                        >
                                                            Email
                                                        </Button>
                                                    </Col>
                                                    <Col md="3">
                                                        <Button
                                                            block
                                                            color="primary"
                                                            onClick={() => {
                                                                navigator.geolocation.getCurrentPosition((position) => {
                                                                    let interactionRequestBody = {
                                                                        "clientName": this.state.clientName,
                                                                        "clientId": this.state.clientId,
                                                                        "interactionType": "checkin",
                                                                        "geolocation":`${position.coords.latitude},${position.coords.longitude}`
                                                                    }

                                                                    axios.post(`${apiConfig.baseUrl}/reports/addInteraction/${Cookies.get('softone_id')}`, interactionRequestBody)
                                                                        .then((response) => {
                                                                            let status = response.status;

                                                                            if (status === 201) {
                                                                                alert('Logged check-in');
                                                                                console.log('Logged user interaction');
                                                                            } else {
                                                                                console.log('Error logging');
                                                                            }
                                                                        });

                                                                });
                                                            }
                                                            }
                                                        >
                                                            Check-in client
                                                        </Button>
                                                    </Col>
                                                    <Col md="3">
                                                        <Button
                                                            block
                                                            color="primary"
                                                            onClick={() => {
                                                                let interactionRequestBody = {
                                                                    "clientName": this.state.clientName,
                                                                    "clientId": this.state.clientId,
                                                                    "interactionType": "phone"
                                                                }

                                                                axios.post(`${apiConfig.baseUrl}/reports/addInteraction/${Cookies.get('softone_id')}`, interactionRequestBody)
                                                                    .then((response) => {
                                                                        let status = response.status;

                                                                        if (status === 201) {
                                                                            console.log('Logged user interaction');
                                                                        } else {
                                                                            console.log('Error logging');
                                                                        }
                                                                    });

                                                                window.open(`tel:${this.state.phone}`)
                                                            }
                                                            }
                                                        >
                                                            Apeleaza
                                                        </Button>
                                                    </Col>
                                                    <Col md="3">
                                                        <Button
                                                            block
                                                            color="primary"
                                                            onClick={() => this.setState({
                                                                content: <CameraInputs clientEmail={this.state.email}
                                                                                       clientName={this.state.clientName}/>
                                                            })}
                                                        >
                                                            Ridicare bilet la ordin
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        {this.state.content}
                    </Row>

                    <Row>
                        <Col className="pr-md-1" md="12">
                            <Table className="tablesorter" responsive>
                                <thead className="text-primary">
                                <tr>
                                    <th>Nume client</th>
                                    <th>Tip interactiune</th>
                                    <th>Locatie</th>
                                    <th>Data</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.clientInteractions.map(interaction => (
                                        <tr>
                                            <td>{interaction.client_name}</td>
                                            <td>{interaction.interaction_type}</td>
                                            <td><a href = {`https://www.google.com/maps/place/${interaction.geolocation}`}>{interaction.geolocation}</a></td>
                                            <td>{interaction.date}</td>
                                        </tr>
                                    ))
                                }
                                </tbody>

                            </Table>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default ClientInteractions;
