/*eslint-disable*/
import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
import Cookies from 'js-cookie';

// reactstrap components
import {
    Row,
    Card,
    CardHeader,
    CardBody,
    Form,
    Col,
    FormGroup,
    Input, CardFooter, Button
} from "reactstrap";
import axios from "axios";
import apiConfig from "../../apiConfig";

class SendMail extends React.Component {
    constructor(props) {
        super(props);

        this.handleMailSubmit = this.handleMailSubmit.bind(this);
    }

    handleMailSubmit(event) {
        event.preventDefault();

        let requestBody = {
            "receiver_name": this.props.clientName,
            "receiver_email": this.props.mailTo,
            "subject": `[Express Instal] ${Cookies.get('name')} - ${document.getElementById('mailSubject').value}`,
            "body": ` ${document.getElementById('mailBody').value}`,
            // "softone_id": Cookies.get('softone_id'),
            // "clientId": this.state.clientId,
            // "interactionType": "email",
        }

        console.log(requestBody);
        axios.post(`${apiConfig.baseUrl}/notification/mail/send`, requestBody).then((response) => {
            let responseData = response.data;

            if (responseData.success) {
                alert('Ati trimis cu succes mail-ul catre client!')

                let interactionRequestBody = {
                    "clientName": this.props.clientName,
                    "clientId": this.props.clientId,
                    "interactionType": "email",
                    "subject": requestBody.subject,
                };
                console.log(interactionRequestBody);

                axios.post(`${apiConfig.baseUrl}/reports/addInteraction/${Cookies.get('softone_id')}`, interactionRequestBody).then((response) => {
                    let status = response.status;

                    if (status == 201)  {
                        console.log('Logged user interaction');
                    }
                    else    {
                        console.log('Error logging');
                    }
                });

            } else {
                alert(responseData.error);
            }
        });
    }

    render() {
        return (
            <Col md={this.props.colSize}>
                <Card>
                    <CardHeader>
                        <h5 className="title">Trimite mail</h5>
                    </CardHeader>
                    <CardBody>
                        <Form>
                            <Row>
                                <Col className="pr-md-1" md="6">
                                    <FormGroup>
                                        <label>Nume</label>
                                        <Input
                                            value={`${Cookies.get('name')}`}
                                            placeholder="Nume agent"
                                            type="text"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="8">
                                    <FormGroup>
                                        <label>Subiect</label>
                                        <Input
                                            placeholder="Introduceti subiect"
                                            type="text"
                                            id="mailSubject"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="8">
                                    <FormGroup>
                                        <label>Mesaj</label>
                                        <Input
                                            cols="80"
                                            id="mailBody"
                                            placeholder="Scrieti corpul mesajului"
                                            rows="8"
                                            type="textarea"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </CardBody>
                    <CardFooter>
                        <Button className="btn-fill" color="primary" type="submit" onClick={this.handleMailSubmit}>
                            Trimite mail catre client
                        </Button>
                    </CardFooter>
                </Card>
            </Col>
        );
    }
}

export default SendMail;
