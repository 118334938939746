/*eslint-disable*/
import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// reactstrap components
import {
    Row,
    Card,
    CardHeader,
    CardBody,
    Form,
    Col,
    FormGroup,
    Input, CardFooter, Button
} from "reactstrap";

import {BsCamera} from "react-icons/bs";
import SignatureConfirmationComponent from 'react-file-input-previews-base64'
import SignaturePad from 'react-signature-pad-wrapper'

class SignatureConfirmation extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Col className="pr-md-1" md={this.props.colSize}>
                <FormGroup>
                    <label htmlFor="signature"> Confirmare primire </label>
                    <br/>
                    <SignaturePad id="signature"
                                  options={{backgroundColor: 'white', penColor: 'rgb(66, 133, 244)'}}
                                  redrawOnResize={true}/>
                </FormGroup>
            </Col>
        );
    }
}

export default SignatureConfirmation;
